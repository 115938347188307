<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 2H1V3H15V2ZM15 13H1V14H15V13ZM9.49473 10.7773H9.51426C9.84629 11.3372 10.3378 11.6172 10.9889 11.6172C11.6822 11.6172 12.2291 11.3665 12.6295 10.8652C13.0299 10.3607 13.2301 9.6901 13.2301 8.85352C13.2301 8.10156 13.051 7.5026 12.693 7.05664C12.3382 6.60742 11.845 6.38281 11.2135 6.38281C10.468 6.38281 9.90163 6.71484 9.51426 7.37891H9.49473V4.09766H8.36191V11.5H9.49473V10.7773ZM9.48496 9.41016V8.79004C9.48496 8.36035 9.60703 8.00228 9.85117 7.71582C10.0986 7.42936 10.4241 7.28613 10.8277 7.28613C11.2086 7.28613 11.5097 7.42611 11.7311 7.70605C11.9557 7.98275 12.068 8.36686 12.068 8.8584C12.068 9.44434 11.9475 9.90169 11.7066 10.2305C11.469 10.556 11.1402 10.7188 10.7203 10.7188C10.3622 10.7188 10.066 10.5918 9.83164 10.3379C9.60052 10.084 9.48496 9.77474 9.48496 9.41016ZM6.47656 11.5H7.5752V8.24805C7.5752 7.00456 6.94369 6.38281 5.68066 6.38281C4.99056 6.38281 4.38184 6.53906 3.85449 6.85156V7.84277C4.33301 7.43262 4.87012 7.22754 5.46582 7.22754C6.1429 7.22754 6.48145 7.58398 6.48145 8.29688L4.99707 8.50684C3.90983 8.66309 3.36621 9.21322 3.36621 10.1572C3.36621 10.5967 3.50456 10.9499 3.78125 11.2168C4.0612 11.4837 4.44694 11.6172 4.93848 11.6172C5.60579 11.6172 6.11198 11.3177 6.45703 10.7188H6.47656V11.5ZM5.43164 9.19043L6.48145 9.04395V9.47852C6.48145 9.84635 6.36751 10.1523 6.13965 10.3965C5.91504 10.6374 5.63021 10.7578 5.28516 10.7578C5.03125 10.7578 4.8278 10.6911 4.6748 10.5576C4.52507 10.4209 4.4502 10.2467 4.4502 10.0352C4.4502 9.74544 4.53158 9.54362 4.69434 9.42969C4.86035 9.3125 5.10612 9.23275 5.43164 9.19043Z"
    />
  </svg>
</template>
