<template>
  <va-card class="header-card">
    <va-card-content>
      <div class="header-controls">
        <!-- One component is chosen for each kind of acceptedValues -->
        <div
          class="mr-2"
          v-for="inputKey in state.searchFilterKeys"
          :key="inputKey"
        >
          <!-- Input -->
          <va-input
            v-if="state.searchFilter[inputKey].acceptedValues === 'string'"
            class="mb-4"
            v-model="state.searchFilter[inputKey].value"
            :label="getLabel(inputKey)"
            :placeholder="getLabel(inputKey)"
            @change="
              $emit('filterInput', { inputKey, value: $event.target.value})
            "
            @keyup.enter="$emit('search')"
          >
            <template #prepend>
              <va-popover
                v-if="inputKey.endsWith('Id')"
                color="primary"
                :message="$t('buttons.matchWholeId')"
                :offset="[0, 10]"
                trigger="click"
              >
                <button type="button" class="match-whole-word">
                  <match-whole-word />
                </button>
              </va-popover>
            </template>
          </va-input>

          <!-- Boolean select -->
          <!-- keydown is used on selects instead of keyup due to chrome issue -->
          <!-- https://github.com/vuejs/vue/issues/4660 -->
          <va-select
            v-else-if="
              state.searchFilter[inputKey].acceptedValues === 'boolean'
            "
            class="mb-4"
            v-model="state.searchFilter[inputKey].value"
            :clearable="true"
            :clear-value="undefined"
            :label="getLabel(inputKey)"
            :options="buildOptions(inputKey)"
            :placeholder="getLabel(inputKey)"
            @keydown.enter="$emit('search')"
            @update:model-value="
              $emit('filterInput', {
                inputKey,
                value: createFilterInputPayload(
                  inputKey,
                  state.searchFilter[inputKey].value
                )
              })
            "
          />

          <!-- Select -->
          <!-- keydown is used on selects instead of keyup due to chrome issue -->
          <!-- https://github.com/vuejs/vue/issues/4660 -->
          <va-select
            v-else-if="
              Array.isArray(state.searchFilter[inputKey].acceptedValues)
            "
            class="mb-4"
            v-model="state.searchFilter[inputKey].value"
            :clearable="true"
            :clear-value="undefined"
            :label="getLabel(inputKey)"
            :options="state.searchFilter[inputKey].acceptedValues"
            :placeholder="getLabel(inputKey)"
            @keydown.enter="$emit('search')"
            @update:model-value="
              $emit('filterInput', {
                inputKey,
                value: state.searchFilter[inputKey].value
              })
            "
          />
        </div>
      </div>
      <div class="buttons-container">
        <va-button flat class="ml-2" @click="clear">
          {{ $t("buttons.clear") }}
        </va-button>
        <va-button
          class="ml-2"
          :loading="props.loading"
          @click="$emit('search')"
        >
          {{ $t("buttons.search") }}
        </va-button>
      </div>
    </va-card-content>
  </va-card>
</template>

<script setup>
import { reactive, toRefs, watch } from "vue";
import { useI18n } from "vue-i18n";
import MatchWholeWord from "@/components/icons/MatchWholeWord.vue";

const { t } = useI18n();
const emit = defineEmits(["clear", "filterInput", "search"]);

const props = defineProps({
  i18nPrefix: {
    type: String,
    required: true
  },
  searchFilter: {
    type: Object,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  }
});
const { searchFilter } = toRefs(props);
watch(searchFilter, (newSearchFilter) => {
 
  state.searchFilter = JSON.parse(JSON.stringify(newSearchFilter));
}, { deep: true }); // Use deep watch to detect nested changes

// Method to determine label text
function getLabel(inputKey) {
  const filterItem = props.searchFilter[inputKey];
  return filterItem.label ? t(filterItem.label) : t(`${props.i18nPrefix}.${inputKey}`);
}

const state = reactive({
  searchFilterKeys: Object.keys(props.searchFilter),
  searchFilter: JSON.parse(JSON.stringify(props.searchFilter))
});

/**
 * Build options for boolean selects
 * @param {string} key
 */
const buildOptions = key => {
  return [
    t(`${props.i18nPrefix}.${key}`),
    t(`${props.i18nPrefix}.${key}False`)
  ];
};

/**
 * Clear search filter values of this component.
 * Also emit to clear its parent's state.
 */
const clear = () => {
  state.searchFilterKeys.forEach(key => {
    state.searchFilter[key].value = undefined;
  });
  emit("clear");
};

/**
 * @param {string} key
 * @param {string} value
 * @returns {boolean|undefined}
 */
const createFilterInputPayload = (key, value) => {
  const options = buildOptions(key);
  const optionsIndex = options.indexOf(value);
  switch (optionsIndex) {
    case 0:
      return true;
    case 1:
      return false;
    default:
      return;
  }
};
</script>

<style lang="scss" scoped>
.buttons-container {
  display: flex;
  justify-content: flex-end;
}

.header-card {
  margin-bottom: 1rem;
}

.header-controls {
  text-align: right;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 8px;
}

.match-whole-word {
  border: 0;
  background-color: inherit;
  height: 16px;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .header-controls {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .header-controls {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
